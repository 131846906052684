<template>
  <Teleport to="body">
    <Transition name="fade">
      <div class="addpopup" @click="$emit('update:modelValue', false)" v-show="modelValue">
        <div class="addpopup__content" @click.stop>
          <div class="addpopup__checks">
            <label>
              <input type="checkbox" :checked="contextEnabled" @input="change">
              <div>Отправлять диалог целиком</div>
            </label>
          </div>
          <div class="addpopup__panel">
            <UserPanel v-if="(Role == 2) && (modelValue)" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { mapState } from 'vuex'
import UserPanel from './UserPanel.vue';
export default {
  props: {
    modelValue: { type: Boolean, default: false },
  },
  methods: {
    change() {
      this.$store.commit('setContextEnabled', !this.contextEnabled);
    }
  },
  computed: {
    ...mapState(['url', 'contextEnabled', 'Role'])
  },
  components: { UserPanel }
}
</script>

<style lang="sass">
.addpopup
  position: fixed
  z-index: 11
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(255,255,255,.6)
  &__content
    min-height: 200px
    width: 550px
    max-width: 100%
    background: colors.$dark
    color: white
    padding: 20px
    border-radius: 12px
    box-shadow: 1px 1px 4px rgba(0,0,0,.3)
    overflow-y: auto
    >*:not(:last-child)
      margin-bottom: 15px
  &__input
    width: 100%
    padding: 5px 10px
    background: white
    border: 1px solid rgba(0,0,0,.15)
  &__checks
    user-select: none
    max-height: 400px
    overflow-y: auto 
    label
      display: flex
      align-items: center
      font-size: 16px
      &:not:last-child
        margin-bottom: 8px
      input
        margin-right: 5px
</style>