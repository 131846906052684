<template>
  <button class="tod" :class="{ tod_selected: current_id === dialog.id }" @click="click">
    <div class="tod__name">{{ dialog.name }}</div>
    <div class="tod__delete" @click.stop.prevent="deleteDialog">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M3 6.52381C3 6.12932 3.32671 5.80952 3.72973 5.80952H8.51787C8.52437 4.9683 8.61554 3.81504 9.45037 3.01668C10.1074 2.38839 11.0081 2 12 2C12.9919 2 13.8926 2.38839 14.5496 3.01668C15.3844 3.81504 15.4756 4.9683 15.4821 5.80952H20.2703C20.6733 5.80952 21 6.12932 21 6.52381C21 6.9183 20.6733 7.2381 20.2703 7.2381H3.72973C3.32671 7.2381 3 6.9183 3 6.52381Z"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5956 22H12.4044C15.1871 22 16.5785 22 17.4831 21.1141C18.3878 20.2281 18.4803 18.7749 18.6654 15.8685L18.9321 11.6806C19.0326 10.1036 19.0828 9.31511 18.6289 8.81545C18.1751 8.31579 17.4087 8.31579 15.876 8.31579H8.12404C6.59127 8.31579 5.82488 8.31579 5.37105 8.81545C4.91722 9.31511 4.96744 10.1036 5.06788 11.6806L5.33459 15.8685C5.5197 18.7749 5.61225 20.2281 6.51689 21.1141C7.42153 22 8.81289 22 11.5956 22ZM10.2463 12.1885C10.2051 11.7546 9.83753 11.4381 9.42537 11.4815C9.01321 11.5249 8.71251 11.9117 8.75372 12.3456L9.25372 17.6087C9.29494 18.0426 9.66247 18.3591 10.0746 18.3157C10.4868 18.2724 10.7875 17.8855 10.7463 17.4516L10.2463 12.1885ZM14.5746 11.4815C14.9868 11.5249 15.2875 11.9117 15.2463 12.3456L14.7463 17.6087C14.7051 18.0426 14.3375 18.3591 13.9254 18.3157C13.5132 18.2724 13.2125 17.8855 13.2537 17.4516L13.7537 12.1885C13.7949 11.7546 14.1625 11.4381 14.5746 11.4815Z"></path>
        </g>
      </svg>
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    dialog: {
      type: Object
    }
  },
  methods: {
    deleteDialog(){
      if (confirm('Удалить диалог "' + this.dialog.name + '"?'))
        return this.$store.dispatch('deleteDialog', this.dialog.id)
    },
    click() {
      if (this.dialog.id == this.current_id) {
        let name = prompt('Укажите новое название диалога')
        if (name)
          this.$store.dispatch('renameDialog', { id: this.dialog.id, name })
      }
      else
        this.$store.dispatch('getDialog', this.dialog.id)
    }
  },
  computed: {
    ...mapState(['current_id'])
  }
}
</script>

<style lang="sass" scoped>
.tod
  width: 100%
  position: relative
  background: none
  border: 1px solid rgba(255,255,255,.2)
  border-left: none
  border-right: none
  cursor: pointer
  &:not(:first-child)
    border-top: none
  
  &__delete
    display: none
    position: absolute
    right: 5px
    width: 50px
    border: none
    background: none
    width: 40px
    padding: 3px 8px
    align-self: stretch
    cursor: pointer
    align-items: center
    top: 0
    bottom: 0
    svg
      fill: colors.$red
    &:hover svg
      fill: lighten(colors.$red, 5)
    &:active svg
      fill: lighten(colors.$red, 10)
  &:hover &__delete
    display: flex
  &_selected
    order: -1
    background: black
  &__name
    padding: 10px 15px
    width: 100%
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    color: white
    font-size: 15px
    font-weight: 500
</style>