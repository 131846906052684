<template>
  <div class="up">
    <div v-if="users && users.length">
      <div class="up__title">Список пользователей:</div>
      <div class="up__user" v-for="u in users" :key="u">
        <div class="up__user-login">{{ u }}</div>
        <div class="up__user-actions">
          <Btn @click="() => del(u)" class="up__user-del" title="Удалить пользователя">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g id="Menu / Close_SM">
                  <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
              </g>
            </svg>
          </Btn>
        </div>
      </div>
    </div>
    <div class="up__title" v-else>Пользователи отсутствуют</div>
    <div class="up__data" v-if="created.user">
      <div class="up__data-title">Последний созданный пользователь:</div>
      <div class="up__data-log">{{ created.user }}</div>
      <div class="up__data-pass">{{ created.pass }}</div>
    </div>
    <Btn class="up__add" :small="true" @click="createUser">Добавить пользователя</Btn>

  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';

export default {
  data: () => ({
    users: [],
    created: {
      user: '',
      password: ''
    }
  }),
  methods: {
    async getUsers() {
      this.users = await this.$store.dispatch('getUsers');
    },
    async createUser() {
      let login = prompt('Введите логин для нового пользователя')
      let pass = await this.$store.dispatch('createUser', login)
      if (pass) {
        this.created.user = login
        this.created.pass = pass
        this.getUsers()
      }
    },
    async del(u) {
      if (confirm('Удалить пользователя ' + u + '?')) {
        let res = await this.$store.dispatch('deleteUser', u)
        if (res)
          this.getUsers()
      }

    }
  },
  mounted() {
    this.getUsers()
  },
  components: { Btn }
}
</script>

<style lang="sass" scoped>
.up
  background: white
  color: black
  border-radius: 12px
  padding: 10px
  &__user
    width: 100%
    max-width: 283px
  &__title
    font-size: 22px
    font-weight: 600
    margin-bottom: 10px
  &__data
    margin: 15px 0
    &-log, &-pass
      font-weight: 700
  &__add
    margin-top: 15px

  &__user
    padding: 10px
    &:hover
      background: rgba(0,0,0,.05)
    &, &-actions
      display: flex
      align-items: center
    &-login
      margin-right: 10px
      font-size: 16px
      flex-grow: 1
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    &-del
      background: colors.$red
      svg
        transform: scale(2)
      &:hover, &:focus-visible
        background: darken(colors.$red,10)
      &:active
        background: darken(colors.$red,15)
</style>