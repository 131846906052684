<template>
  <div class="dialog">
    <div class="dialog__messages">
      <Message :message="m" v-for="(m, i) in messages" :key="i" />
    </div>
    <div class="dialog__controls" :class="{ 'dialog__controls_g4': g4 }">
      <div class="dialog__controls-wrapper">
        <textarea :placeholder="placeholder" class="dialog__input" v-model="text" @keydown.enter.exact.prevent @keypress.enter.exact.prevent @keyup.enter.exact.prevent="send"></textarea>
        <div class="dialog__controls-btns">
          <Btn v-if="instream && textmode" @click="$store.dispatch('abort')" class="dialog__abort">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g id="Menu / Close_SM">
                  <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
              </g>
            </svg>
          </Btn>
          <Btn v-else @click="send" class="dialog__send">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" class="icon-sm m-1 md:m-0">
              <path d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z" fill="white" stroke="none"></path>
            </svg>
          </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from './Message.vue';
import { mapState, mapGetters } from 'vuex';
import Btn from './buttons/Btn.vue';
export default {
  components: { Message, Btn },
  data: () => ({
    text: ''
  }),
  computed: {
    ...mapState(['messages', 'instream','mode']),
    ...mapGetters(['g4', 'messagesLength']),
    placeholder(){
      if (this.textmode)
        return null
      return 'Type image description here'
    },
    textmode() {
      return this.mode == 'text'
    }
  },
  async mounted() {
    //await this.$store.dispatch('getDialog')
    //this.$store.dispatch('scroll')
  },
  methods: {
    send(){
      if (this.textmode)
        this.$store.dispatch('sendMessage', this.text)
      else 
        this.$store.dispatch('sendImageMessage', this.text)
    }
  },
  watch: {
    messagesLength: {
      handler() {
        this.$nextTick(() => this.$store.dispatch('scroll'))
        this.text = ''
      },
      immediate: true,
    },
    instream(v) {
      if (v) {
        this.text = ''
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.dialog
  width: 100%
  padding: 20px 0 0
  //background: darken(transparentize(colors.$blue, .96), .2)
  max-width: 100%
  margin: 30px auto 0
  display: flex
  flex-direction: column
  &__messages
    flex-grow: 1
    margin-bottom: 134px

  &__controls
    z-index: 5
    position: fixed
    padding: 26px 50px
    left: var(--sidebar_width)
    width: calc(100% - var(--sidebar_width))
    bottom: 0
    display: flex
    justify-content: center
    background: linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#fff 54.73%)
    &-wrapper
      display: flex
      justify-content: space-between
      gap: 10px
      width: 768px
      max-width: 100%
    &-btns
      position: absolute
      right: 10px
      top: 0
      bottom: 0
      display: flex
      flex-direction: column
      justify-content: center
      gap: 10px
      >button
        width: auto
        height: auto
        aspect-ratio: 1
        padding: 9px
      
        svg
          min-width: 16px
          width: 16px
          height: 16px

  &__input
    width: auto
    flex-grow: 1
    resize: none
    height: 82px
    display: block
    padding: 12px
    padding-right: 50px
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px
    border-radius: 12px
    background: white
    border: 1px solid rgba(0,0,0,.1)
    font-size: 16px
    line-height: 1.2
    scrollbar-width: thin
    &::-webkit-scrollbar 
      width: 6px
    &::-webkit-scrollbar-track 
      background: white
    &::-webkit-scrollbar-thumb 
      background: rgba(0,0,0,0.2) 
    &:focus
      border: 1px solid rgba(0,0,0,.2)
    outline: none !important
  & &__send:nth-child(n)
    background: colors.$realgreen
    &:hover, &:focus-visible
      background: darken(colors.$realgreen,10)
    &:active
      background: darken(colors.$realgreen,15)
  & &__abort:nth-child(n)
    background: colors.$red
    svg
      transform: scale(2)
    &:hover, &:focus-visible
      background: darken(colors.$red,10)
    &:active
      background: darken(colors.$red,15)
  & &__controls_g4 &__send:nth-child(n)
    background: colors.$viol
    &:hover, &:focus-visible
      background: darken(colors.$viol,10)
    &:active
      background: darken(colors.$viol,15)

  & &__clear:nth-child(n)
    background: colors.$neutral
    &:hover:nth-child(n), &:focus-visible:nth-child(n)
      background: colors.$red
    &:active:nth-child(n)
      background: darken(colors.$red,10)

</style>