<template>
  <div class="main">
    <div class="main__sidebar">
      <NewDialog />
      <ToDialog v-for="d in dialogs" :key="d.id" :dialog="d" />
    </div>
    <div class="main__content">
      <TopPanel />
      <Dialog />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopPanel from '../components/TopPanel.vue';
import Dialog from '../components/Dialog.vue';
import ToDialog from '../components/ToDialog.vue';
import NewDialog from '../components/newDialog.vue';
import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

export default {
  name: 'Main',
  components: { TopPanel, Dialog, ToDialog, NewDialog },
  computed: {
    ...mapState(['dialogs', 'Role'])
  },
  methods: {
    vuePlugin() {
      function hljsDefineVue(e){return{subLanguage:"xml",contains:[e.COMMENT("\x3c!--","--\x3e",{relevance:10}),{begin:/^(\s*)(<script>)/gm,end:/^(\s*)(<\/script>)/gm,subLanguage:"javascript",excludeBegin:!0,excludeEnd:!0},{begin:/^(\s*)(<script lang=["']ts["']>)/gm,end:/^(\s*)(<\/script>)/gm,subLanguage:"typescript",excludeBegin:!0,excludeEnd:!0},{begin:/^(\s*)(<style(\sscoped)?>)/gm,end:/^(\s*)(<\/style>)/gm,subLanguage:"css",excludeBegin:!0,excludeEnd:!0},{begin:/^(\s*)(<style lang=["'](scss|sass)["'](\sscoped)?>)/gm,end:/^(\s*)(<\/style>)/gm,subLanguage:"scss",excludeBegin:!0,excludeEnd:!0},{begin:/^(\s*)(<style lang=["']stylus["'](\sscoped)?>)/gm,end:/^(\s*)(<\/style>)/gm,subLanguage:"stylus",excludeBegin:!0,excludeEnd:!0}]}}
      hljs.registerLanguage('vue', hljsDefineVue)
    }
  },
  created() {
    hljs.initHighlightingOnLoad();
    this.vuePlugin()
    //eslint-disable-next-line
    window.marked = new Marked(
      markedHighlight({
        langPrefix: 'hljs language-',
        highlight(code, lang) {
          const language = hljs.getLanguage(lang) ? lang : 'plaintext';
          return hljs.highlight(code, { language }).value;
        }
      })
    );
  },
  mounted() {
    this.$store.dispatch('getDialogs')
  },
}
</script>
<style lang="sass">

.main
  color: colors.$dark
  --sidebar_width: 300px
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black
  .btn
    background: colors.$dark
    &:hover, &:focus-visible
      background: darken(colors.$dark,10)
    &:active
      background: darken(colors.$dark,15)

  position: relative
  min-height: 100vh
  //background: url('~@/assets/bg.webp')
  background: white
  background-repeat: repeat
  display: flex
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
    z-index: -1
  &__sidebar
    display: flex
    flex-direction: column
    padding: 20px 0 0
    background: colors.$dark
    align-self: stretch
    overflow-y: auto
    scrollbar-width: thin
    flex-shrink: 0
    flex-basis: var(--sidebar_width)

    &::-webkit-scrollbar 
      width: 6px
    &::-webkit-scrollbar-track 
      background: white
    &::-webkit-scrollbar-thumb 
      background: rgba(0,0,0,0.2) 
  &__content
    display: flex
    flex-grow: 1
    flex-direction: column
    align-self: flex-start
    align-items: flex-start
    min-height: 100vh

    *
      position: relative
    
  &__list
    min-width: 200px
    max-width: 300px
    margin-right: 30px
  &__viewer
    flex-grow: 1
  &__data
    align-self: stretch
    margin-left: 20px
    flex-grow: 1
</style>