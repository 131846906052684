<template>
  <LoadScreen />
  <router-view v-if="Role != null" v-slot="{ Component }">
    <transition name="fade" mode="ease-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import LoadScreen from './views/LoadScreen.vue'
import { mapState } from 'vuex'

export default {
  components: { LoadScreen },
  data: () => ({
    inited: false
  }),
  async created() {
    this.$store.commit('setUrl', window.location.origin + '/')
  },
  mounted() {
    this.$store.dispatch('checkAuth')
    document.head.querySelector('title').innerHTML = 'Chatgpt'
    setTimeout(() => {
      if (!this.inited)
        this.init()
    }, 100)
  },
  methods: {
    init() {
      this.inited = true
      if (this.Role) {
        this.$router.push('/app')
      }
      else
        this.$router.push('/login')
    }
  },
  computed: {
    ...mapState(['Role'])
  },
  watch: {
    Role() {
      this.init()
    }
  }
}
</script>

<style lang="sass">

body
  scrollbar-width: thin
  color: colors.$dark
  &::-webkit-scrollbar 
    width: 6px
  &::-webkit-scrollbar-track 
    background: white
  &::-webkit-scrollbar-thumb 
    background: rgba(0,0,0,0.2) 
  *, *::after, *::before
    box-sizing: border-box
    font-family: 'Open Sans', sans-serif

.fade-enter-active, .fade-leave-active
  transition: opacity .4s ease
.fade-enter-from, .fade-leave-to
  opacity: 0
  user-select: none
body
  margin: 0
  padding: 0
  ul, li
    margin: 0
    padding: 0
    list-style-type: none
</style>
